




















import {Component, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import {FOCUS_GAME_TYPE_ID, FOCUS_LEAGUE_ID, FOCUS_SEASON_ID} from '@/store/context.store';
import DataTable from '@/components/stats/DataTable.vue';
import StatsTableLayout from '@/components/layout/StatsTableLayout.vue';
import StatsSeasonSelection from '@/components/stats/selection/StatsSeasonSelection.vue';
import StatsLeagueSelection from '@/components/stats/selection/StatsLeagueSelection.vue';
import StatsGameTypeSelection from '@/components/stats/selection/StatsGameTypeSelection.vue';
import StatsTeamSelection from '@/components/stats/selection/StatsTeamSelection.vue';
import { CustomComponent } from '@/model/site/components.model';

@Component({
  components: {StatsTableLayout, DataTable},
})
export default class PlayerStatsPage extends Vue {

  @Getter(FOCUS_SEASON_ID) focusSeasonId!: string;
  @Getter(FOCUS_GAME_TYPE_ID) focusGameTypeId!: string;
  @Getter(FOCUS_LEAGUE_ID) focusLeagueId!: string;

  get selectionComponents(): CustomComponent[] {
    return [
      {component: StatsLeagueSelection},
      {component: StatsSeasonSelection},
      {component: StatsGameTypeSelection},
      {component: StatsTeamSelection},
    ];
  }

}
