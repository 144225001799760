


































































import {Component, Prop, Vue} from 'vue-property-decorator';
import MetaMixin from '@/mixins/meta.mixin';
import RouteMixin from '@/mixins/route.mixin';
import MobileMixin from '@/mixins/mobile.mixin';
import {HfPage, PlayerContentType} from '@/model/site/components.model';
import SportLayout from '@/components/layout/SportLayout.vue';
import HfAdPlaceholder from '@/components/ads/HfAdPlaceholder.vue';
import HfAdBox from '@/components/ads/HfAdBox.vue';
import PLAYER_SERVICE from '@/services/player.service';
import {SET_FOCUS_SEASON_ID} from '@/store/context.store';
import Player from '@/model/core/player.model';
import {StatsData} from '@/model/stats/stats.model';
import {nil} from '@/utils/common.util';
import DataTable from '@/components/stats/DataTable.vue';
import SelectionTitle from '@/components/ui/SelectionTitle.vue';
import StatsSeasonSelection from '@/components/stats/selection/StatsSeasonSelection.vue';
import PlayerPicture from '@/components/player/PlayerPicture.vue';
import HfTeamLogo from '@/components/shared/HfTeamLogo.vue';
import CountryFlag from '@/components/shared/CountryFlag.vue';
import {COUNTRY_SERVICE} from '@/services/country.service';
import PlayerPictureCarousel from '@/components/player/PlayerPictureCarousel.vue';
import Loading from 'vue-loading-overlay';
import PlayersStats from '@/components/player/PlayersStats.vue';

@Component({
  components: {
    PlayersStats,
    PlayerPictureCarousel,
    CountryFlag,
    HfTeamLogo,
    Loading,
    PlayerPicture, StatsSeasonSelection, SelectionTitle, DataTable, HfAdBox, HfAdPlaceholder, SportLayout
  },
  mixins: [MetaMixin, RouteMixin, MobileMixin],
})
export default class PlayerInfoPage extends Vue implements HfPage {

  @Prop({required: true, type: String}) playerId!: string;
  @Prop({required: true, type: String}) contentType!: PlayerContentType;

  static readonly PICTURES_TAB_IDX = 1;

  player?: Player = nil();
  seasons: StatsData[] = nil();

  noData: boolean = false;
  loaded: boolean = false;

  get headTitle(): string {
    return `${this.siteTitle} | ${this.siteName}`;
  }

  get headDescription(): string {
    return this.playerFullName;
  }

  get playerFullName(): string {
    return this.player ? this.player.name() : '';
  }

  get canonicalUrl(): string {
    return this.urlFromCurrentPath;
  }

  get imageDesc() {
    return undefined;
  }

  get siteTitle() {
    return this.player ? this.player.name() : '';
  }

  created() {
    this.loadPlayer();
  }

  get natioanlityIds(): string[] {
    if (!this.player) {
      return [];
    }
    const nationalityIds = [];
    if (this.player.nationalityId1) {
      const iso2 = COUNTRY_SERVICE.hfToCountryIso2(this.player.nationalityId1);
      if (iso2) {
        nationalityIds.push(iso2);
      }
    }
    if (this.player.nationalityId2 && this.player.nationalityId2 !== this.player.nationalityId1) {
      const iso2 = COUNTRY_SERVICE.hfToCountryIso2(this.player.nationalityId2);
      if (iso2) {
        nationalityIds.push(iso2);
      }
    }
    return nationalityIds;
  }

  get teamIds(): string[] {
    if (!this.player) {
      return [];
    }
    const teamIds = [];
    if (this.player.currentTeamId) {
      teamIds.push(this.player.currentTeamId);
    }
    if (this.player.alternateTeamId && this.player.alternateTeamId !== this.player.currentTeamId) {
      teamIds.push(this.player.alternateTeamId);
    }
    return teamIds;
  }

  get propertyList() {
    const props: string[] = [];
    if (!this.player) {
      return props;
    }
    if (this.player.position !== 'unbekannt' && this.player.position) {
      props.push(this.$t(`position.${this.player.position}`));
    }
    if (this.player.jerseyNr()) {
      props.push(`#${this.player.jerseyNr()}`);
    }
    const age = this.player.age(true);
    if (age) {
      props.push(`${age} Jahre`);
    }
    if (this.player.birthday()) {
      props.push(`${this.player.birthday()}`);
    }
    if (this.player.height()) {
      props.push(`${this.player.height()}`);
    }
    if (this.player.weight()) {
      props.push(`${this.player.weight()}`);
    }
    return props;
  }

  loadPlayer() {
    PLAYER_SERVICE.playerById(this.playerId).then((response: any) => {
      this.player = new Player(response.data);
      if (!this.player.exists()) {
        const errorMsg = `Der Spieler '${this.playerId}' existiert nicht`;
        this.$router.push(`/404?path=${this.$route.path}&message=${errorMsg}`);
      }
    });

    PLAYER_SERVICE.seasonsForPlayer(this.playerId)
      .then((response: any) => {
        this.seasons = response.data;
        if (this.seasons && this.seasons.length) {
          this.$store.commit(SET_FOCUS_SEASON_ID, this.seasons[0].seasonId);
          this.noData = false;
        } else {
          this.noData = true;
        }
      }).finally(() => this.loaded = true);
  }

  get activeTab(): number {
    switch (this.contentType) {
      case PlayerContentType.STATS:
        return 0;
      case PlayerContentType.PICTURES:
        return PlayerInfoPage.PICTURES_TAB_IDX;
      default:
        Vue.$log.warn('Illegal PlayerContentType', this.contentType);
        this.$router.push(`/404?path=${this.$route.path}`);
        return -1;
    }
  }

  set activeTab(idx: number) {
    // empty
  }

  onTabChange(index: number) {
    if (index === PlayerInfoPage.PICTURES_TAB_IDX) {
      const pictures: any = this.$refs['pictures'];
      if (pictures) {
        pictures.forceRefresh();
      } else {
        Vue.$log.warn('Could not refresh pictures, wrong "ref"?');
      }
    }
  }

}
