






























import {Component, Vue} from 'vue-property-decorator';
import { teamstatsTables } from '@/services/tables/teamstats.tables';
import {toArray} from '@/utils/common.util';
import { Prop } from 'vue-property-decorator';
import {ROUTES} from '@/router';

@Component({
  components: {},
})
export default class StatsTeamStatsSelection extends Vue {

  @Prop({required: true, type: Object}) currentStats!: any;

  stats = toArray(teamstatsTables);

  selectStats(stats: any) {
    if (this.currentStats && stats.query === this.currentStats.query) {
      return;
    }
    this.updateCurrentStats(stats.query);
    this.$router.replace({name: ROUTES.STATS_TEAM, params: {preselectedStatsQuery: stats.query}});
  }

  updateCurrentStats(selectedStatsQuery: string) {
    if (selectedStatsQuery) {
      this.currentStats = this.stats.find(s => s.query === selectedStatsQuery);
    } else {
      this.currentStats = this.stats[0];
    }
    return this.currentStats;
  }
}
