



















import {Component, Vue} from 'vue-property-decorator';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class SelectionTitle extends Vue {
  @Prop({required: false}) main?: boolean;
}
