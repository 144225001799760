



















































































import {Component, Vue} from 'vue-property-decorator';
import StatsLeagueSelection from '@/components/stats/selection/StatsLeagueSelection.vue';
import {
  CONTEXT,
  FOCUS_GAME_TYPE_ID,
  FOCUS_LEAGUE_ID,
  FOCUS_SEASON_ID,
  SET_FOCUS_LEAGUE_ID
} from '@/store/context.store';
import {Context} from '@/model/site/context.model';
import {Getter} from 'vuex-class';
import DataTable from '@/components/stats/DataTable.vue';
import {StatsTopGameTO, StatsTopPlayerTO} from '@/model/stats/stats.model';
import Loading from 'vue-loading-overlay';
import StatsPortalLayout from '@/components/layout/StatsPortalLayout.vue';
import PanelBox from '@/components/ui/PanelBox.vue';
import StatsGameTypeSelection from '@/components/stats/selection/StatsGameTypeSelection.vue';
import StatsSeasonSelection from '@/components/stats/selection/StatsSeasonSelection.vue';
import StatsTeamSelection from '@/components/stats/selection/StatsTeamSelection.vue';
import {CustomComponent} from '@/model/site/components.model';

@Component({
  components: {
    StatsPortalLayout, DataTable, PanelBox, Loading
  },
})
export default class StatsPortalPage extends Vue {

  @Getter(CONTEXT) context!: Context;
  @Getter(FOCUS_SEASON_ID) focusSeasonId!: string;
  @Getter(FOCUS_LEAGUE_ID) focusLeagueId!: string;
  @Getter(FOCUS_GAME_TYPE_ID) focusGameTypeId!: string;

  get selectionComponents(): CustomComponent[] {
    return [
      {component: StatsLeagueSelection},
      {component: StatsSeasonSelection},
      {component: StatsGameTypeSelection},
      {component: StatsTeamSelection},
    ];
  }

  get statsTopGame() {
    return [
      new StatsTopGameTO('Zuschauerrekord', 'attendance_max', 'attendance'),
      new StatsTopGameTO('Minuskulisse', 'attendance_min', 'attendance'),
      new StatsTopGameTO('Max. Tore', 'goals_max', 'goalsMax'),
      new StatsTopGameTO('Min. Tore', 'goals_min', 'goalsMin'),
      new StatsTopGameTO('2-Minuten-Strafen', 'min2'),
      new StatsTopGameTO('Tore Total', 'goals'),
      new StatsTopGameTO('Strafen Total', 'pim'),
      new StatsTopGameTO('Strafen Heim', 'pim_H', 'pimH'),
      new StatsTopGameTO('Strafen Auswärts', 'pim_A', 'pimA'),
    ];
  }

  get statsTopPlayer() {
    // gwg, shg,'ppt'
    const topscorer = new StatsTopPlayerTO('Topscorer', 'playerstats', 'top', ['g', 'a', 'sogPct', 'ppg', 'p'], 'p', 12);
    // ps, pimGp
    const penaltyMinutes = new StatsTopPlayerTO('Strafminuten', 'playerstats', 'top', ['min2', 'min5', 'min10', 'disc', 'pim'], 'pim', 12);
    return [topscorer, penaltyMinutes];
  }

  get statsTopGoalie() {
    const gkstatsMax = new StatsTopPlayerTO('Fangquote ↓', 'goaliestats', 'top', ['assignments', 'ga', 'ga_60', 'svPct'], 'sv_pct', 5);
    const gkstatsMin = new StatsTopPlayerTO('Gegentore pro 60\' ↓', 'goaliestats', 'top', ['assignments', 'ga', 'svPct', 'ga_60'], 'ga_60_reverse', 5);
    return [gkstatsMax, gkstatsMin];
  }

  /** same as in LeaderStatsPage */
  get boxes() {

    const goals = new StatsTopPlayerTO('Tore', 'playerstats', 'top', 'g');
    const assists = new StatsTopPlayerTO('assists', 'playerstats', 'top', 'g');
    const min2 = new StatsTopPlayerTO('Zwei-Minuten-Strafen', 'playerstats', 'top', 'min2');
    const goalieSvPct = new StatsTopPlayerTO('Fangquote', 'goaliestats', 'top', 'svPct', 'sv_pct');
    const goalieGa = new StatsTopPlayerTO('Gegentore pro 60\'', 'goaliestats', 'top', 'ga_60', 'ga_60');
    const stats = [goals, assists, min2, goalieSvPct, goalieGa];

    const statsLeaders = stats.map(s => {
      return {
        title: s.title,
        component: DataTable,
        props: {
          resource: s.resource,
          query: s.query,
          fieldIds: s.fieldIds,
          queryParams: {
            season: this.focusSeasonId,
            league: this.focusLeagueId,
            gameType: this.focusGameTypeId,
            limitRows: s.limitRows,
            orderBy: s.sortField
          },
          showHeader: false,
          suppressPaging: true,
        },
      };
    });

    return [...statsLeaders];
  }

  beforeRouteUpdate(to: any, from: any, next: any) {
    try {
      if (to.params.leagueId) {
        this.$store.commit(SET_FOCUS_LEAGUE_ID, to.params.leagueId);
      }
    } finally {
      next();
    }
  }

}
