const attendance = {
	title: 'Zuschauer',
	query: 'attendance',
	fields: [
		'gp',
		'attendanceMin',
		'attendanceMax',
		'attendanceDev',
		'attendanceSum',
		'attendanceAvg',
	]
};

const pp = {
	title: 'Powerplay',
	query: 'powerplay',
	fields: [
		'gp',
		'gteam',
		'gopp',
		'ppGoalsFor',
		'ppGoalsPct',
		'ppGoalsAgainst',
		'ppOpp',
		'ppTime',
		'ppGoalTime',
		'ppQuote',
	]
};

const sh = {
	title: 'Unterzahl',
	query: 'boxplay',
	fields: [
		'gp',
		'gteam',
		'gopp',
		'shGoalsAgainst',
		'shGoalsAgainstPct',
		'shGoalsFor',
		'shOpp',
		'shTime',
		'shGoalTime',
		'shQuote',
	]
};

const leading = {
	title: 'Führungszeiten',
	query: 'leading',
	fields: [
		'gp',
		'lag3_10Pct',
		'lag2Pct',
		'lag1Pct',
		'lagPct',
		'eqPct',
		'leadPct',
		'lead1Pct',
		'lead2Pct',
		'lead3_10Pct',
		'avgLeading',
	]
};

const penalties = {
	title: 'Strafen',
	query: 'penalties',
	fields: [
		'gp',
		'min2Min',
		'pimMin',
		'psMax',
		'min2Max',
		'min10Max',
		'discMax',
		'pimMax',
		'ps',
		'min2',
		'min5',
		'min10',
		'disc',
		'pim',
		'psOpp',
		'min2Opp',
		'min5Opp',
		'min10Opp',
		'discOpp',
		'pimOpp',
		'min2Gp',
		'pimGp',
		'min2GpOpp',
		'pimGpOpp'
	]
};

const difference = {
	title: 'Tordifferenzen',
	query: 'difference',
	fields: [
		'gp',
		'otPenN',
		'n',
		'diffMi6',
		'diffMi5',
		'diffMi4',
		'diffMi3',
		'diffMi2',
		'diffMi1',
		'diff_0',
		'diffPl1',
		'diffPl2',
		'diffPl3',
		'diffPl4',
		'diffPl5',
		'diffPl6',
		's',
		'otPenS',
	]
};

const goals = {
	title: 'Tore',
	query: 'goals',
	fields: [
		'gp',
		'gteam',
		'gopp',
		'ppGoalsFor',
		'shGoalsAgainst',
		'shGoalsFor',
		'ppGoalsAgainst',
		'en',
		'enOpp',
		'wg',
		'wgOpp',
		'sog',
		'sogOpp',
		'sogPct',
		'sogPctOpp',
		'plmi',
		'plmiOpp',
	]
};

const overview = {
	title: 'Übersicht',
	query: 'overview',
	fields: [
		'gp',
		's',
		'n',
		'otPenS',
		'otPenN',
		'gteam',
		'gopp',
		'attendanceAvg',
		'sogPct',
		'sogPctOpp',
		'min2',
		'min5',
		'min10',
		'disc',
		'min2Gp',
		'ppGoalTime',
		'ppQuote',
		'shGoalTime',
		'shQuote',
		'avgLeading',
	]
};

export const teamstatsTables = {
	overview,
	penalties,
	pp,
	sh,
	attendance,
	goals,
	leading,
	difference,
};
