































import {Component, Vue} from 'vue-property-decorator';
import {GameTypeId} from '@/model/core/core-data.model';
import {FOCUS_GAME_TYPE_ID, SET_FOCUS_GAME_TYPE_ID} from '@/store/context.store';
import {Getter} from 'vuex-class';

@Component({
  components: {},
})
export default class StatsGameTypeSelection extends Vue {

  @Getter(FOCUS_GAME_TYPE_ID) focusGameTypeId!: GameTypeId;

  gameTypeIds: GameTypeId[] = ['m', 'p', 'r', 'l'];

  select(gameTypeId: GameTypeId) {
    this.updateGameTypeId(gameTypeId);
  }

  updateGameTypeId(gameTypeId: GameTypeId) {
    this.$store.commit(SET_FOCUS_GAME_TYPE_ID, gameTypeId);
  }
}
