























































import {Component, Prop, Vue} from 'vue-property-decorator';
import {StatsData} from '@/model/stats/stats.model';
import Player from '@/model/core/player.model';
import StatsSeasonSelection from '@/components/stats/selection/StatsSeasonSelection.vue';
import DataTable from '@/components/stats/DataTable.vue';
import {FOCUS_SEASON_ID} from '@/store/context.store';
import {Getter} from 'vuex-class';

@Component({
  components: {DataTable, StatsSeasonSelection},
})
export default class PlayersStats extends Vue {

  @Prop({required: true, type: Object}) player!: Player;
  @Prop({required: true, type: Array}) seasons!: StatsData[];

  @Getter(FOCUS_SEASON_ID) focusSeasonId!: string;

  get playerId() {
    return this.player.id;
  }

  get noData() {
    return !this.seasons || !this.seasons.length;
  }

}
