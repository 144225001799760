









































import {IndexedTeam, teamSeasonKey, IndexedTeams} from '@/model/site/state.model';
import {Component, Vue, Watch} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import TEAM_SERVICE from '@/services/team.service';
import {FOCUS_LEAGUE_ID, FOCUS_SEASON_ID, SET_FOCUS_TEAM_ID, REMOVE_FOCUS_TEAM_ID, FOCUS_TEAM_ID} from '@/store/context.store';
import {LeagueId, Team} from '@/model/core/core-data.model';
import S from 'string';


@Component({
  components: {},
})
export default class StatsTeamSelection extends Vue {

  @Getter(FOCUS_SEASON_ID) focusSeasonId!: string;
  @Getter(FOCUS_LEAGUE_ID) focusLeagueId!: LeagueId;
  @Getter(FOCUS_TEAM_ID) focusTeamId!: string | undefined;

  static readonly TRUNCATE_LONG_TEAM_NAME = 15;

  teamsLoaded = false;
  teams: IndexedTeams = {};
  currentTeams: IndexedTeam = {};

  get teamSelection() {
    if (!this.currentTeams) {
      return [];
    }
    return Object.keys(this.currentTeams).map(teamId => {
      return {text: this.currentTeams[teamId].longName, value: teamId};
    });
  }

  get selectedTeamText() {
    let text = this.currentTeams && this.focusTeamId && this.currentTeams[this.focusTeamId] && this.currentTeams[this.focusTeamId].longName || 'Team auswählen';
    text = S(text).truncate(StatsTeamSelection.TRUNCATE_LONG_TEAM_NAME);
    return text;
  }

  get key(): string {
    return teamSeasonKey(this.focusSeasonId, this.focusLeagueId);
  }

  created() {
    this.loadTeams();
  }

  @Watch(FOCUS_SEASON_ID)
  onFocusSeasonIdChange(newValue: string, oldValue: string) {
    if (newValue === oldValue) {
      return;
    }
    this.loadTeams();
  }

  @Watch(FOCUS_LEAGUE_ID)
  onFocusLeagueIdChange(newValue: LeagueId, oldValue: LeagueId) {
    if (newValue === oldValue) {
      return;
    }
    this.loadTeams();
  }

  updateTeam(team: Team) {
    this.$store.commit(SET_FOCUS_TEAM_ID, team.teamId);
  }

  removeTeam() {
    this.$store.commit(REMOVE_FOCUS_TEAM_ID);
  }

  loadTeams() {
    if (!this.teams[this.key]) {
      TEAM_SERVICE.teams(this.focusSeasonId, this.focusLeagueId).then((teams: IndexedTeam) => {
        this.teams[this.key] = teams;
        this.currentTeams = teams;
        this.teamsLoaded = true;
        this.removeTeamIfNecessary();
      });
    } else {
      this.removeTeamIfNecessary();
    }
  }

  removeTeamIfNecessary() {
    if (!this.teams || !this.teams[this.key] || (this.focusTeamId && !this.teams[this.key][this.focusTeamId])) {
      this.removeTeam();
    }
  }

}
