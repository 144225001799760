















import {Component, Prop, Vue} from 'vue-property-decorator';
import StatsBaseLayout from '@/components/layout/StatsBaseLayout.vue';
import { CustomComponent } from '@/model/site/components.model';

@Component({
  components: {
    StatsBaseLayout,
  },
})
export default class StatsPortalLayout extends Vue {

  @Prop({default: () => []}) selectionComponents!: CustomComponent[];

}
