import Vue from 'vue';
import {Mixin} from 'vue-mixin-decorator';

@Mixin
export default class CarouselMixin extends Vue {

	get carouselRef() {
		return 'carousel';
	}

	mounted() {
		this.forceRefresh();
	}

	forceRefresh() {
		// see workaround for Vue Carousel Bug #138: https://bit.ly/2BRCPkA
		setTimeout(() => {
			const carouselRef: any = this.$refs[this.carouselRef];
			if (carouselRef) {
				Vue.$log.debug('refresh Carousel');
				carouselRef.onResize();
				carouselRef.goToPage(0);
			} else {
				Vue.$log.warn(`Could not refresh carousel, maybe wrong ref, must be '${this.carouselRef}'`);
			}
		}, 0);
	}
}
