




































import {Component, Prop, Vue} from 'vue-property-decorator';
import PLAYER_SERVICE from '@/services/player.service';
import {nil} from '@/utils/common.util';
import {Carousel, Slide} from '@noim/vue-carousel';
import Player, {IPlayerPicture} from '@/model/core/player.model';
import Loading from 'vue-loading-overlay';
import CarouselMixin from '@/mixins/carousel.mixin';

@Component({
  mixins: [CarouselMixin],
  components: {Carousel, Slide, Loading},
})
export default class PlayerPictureCarousel extends Vue {

  @Prop({required: true, type: Object}) player!: Player;

  pictures: IPlayerPicture[] = nil();
  loaded = false;
  hasPictures = false;

  created() {
    this.$wait.start('waitingPlayerPictures');
    PLAYER_SERVICE.playerPictures(this.playerId, 600)
      .then((response: any) => {
        this.pictures = response.data;
        this.hasPictures = this.pictures && this.pictures.length > 0;
        this.loaded = true;
      })
      .catch((error: any) => this.hasPictures = false)
      .finally(() => this.$wait.end('waitingPlayerPictures'));
  }

  altDescription(picture: IPlayerPicture, idx: number) {
    return `${this.playerName || this.playerId} [${idx}]`;
  }

  get playerId() {
    return this.player.id;
  }

  get playerName() {
    return this.player.name();
  }

}
