



























import DataTable from '@/components/stats/DataTable.vue';
import {Component, Prop, Vue} from 'vue-property-decorator';
import StatsTableLayout from '@/components/layout/StatsTableLayout.vue';
import {Context} from '@/model/site/context.model';
import {Getter} from 'vuex-class';
import {CONTEXT, FOCUS_GAME_TYPE_ID, FOCUS_LEAGUE_ID, FOCUS_SEASON_ID} from '@/store/context.store';
import StatsLeagueSelection from '@/components/stats/selection/StatsLeagueSelection.vue';
import StatsGameTypeSelection from '@/components/stats/selection/StatsGameTypeSelection.vue';
import StatsSeasonSelection from '@/components/stats/selection/StatsSeasonSelection.vue';
import PanelBox from '@/components/ui/PanelBox.vue';
import {ROUTES} from '@/router';
import {toArray} from '@/utils/common.util';
import {teamstatsTables} from '@/services/tables/teamstats.tables';
import StatsTeamStatsSelection from '@/components/stats/selection/StatsTeamStatsSelection.vue';
import { CustomComponent } from '@/model/site/components.model';

@Component({
  components: {
    StatsTeamStatsSelection,
    StatsGameTypeSelection,
    StatsLeagueSelection,
    StatsSeasonSelection,
    StatsTableLayout,
    DataTable,
    PanelBox
  },
})
export default class TeamStatsPage extends Vue {

  @Prop() preselectedStatsQuery?: string;

  @Getter(CONTEXT) context!: Context;
  @Getter(FOCUS_SEASON_ID) focusSeasonId!: string;
  @Getter(FOCUS_LEAGUE_ID) focusLeagueId!: string;
  @Getter(FOCUS_GAME_TYPE_ID) focusGameTypeId!: string;

  readonly homeAway = [
    {key: '', value: 'Total'},
    {key: 'h', value: 'Heim'},
    {key: 'a', value: 'Auswärts'},
  ];

  stats = toArray(teamstatsTables);
  currentStats: any = {};

  beforeMount() {
    if (this.preselectedStatsQuery) {
      this.currentStats = this.stats.find(s => s.query === this.preselectedStatsQuery);
    } else {
      this.currentStats = this.stats[0];
    }
  }


  get selectionComponents(): CustomComponent[] {
    return [
      {component: StatsLeagueSelection},
      {component: StatsSeasonSelection},
      {component: StatsGameTypeSelection},
      {component: StatsTeamStatsSelection, props: {currentStats: this.currentStats}},
    ];
  }

  get query() {
    return this.currentStats.query;
  }

  get fields() {
    return this.currentStats.fields;
  }

  queryParams(ha: string) {
    return {
      season: this.focusSeasonId,
      league: this.focusLeagueId,
      gameType: this.focusGameTypeId,
      orderBy: 'teamId',
      ha
    };
  }

}
