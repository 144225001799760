




























import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import {FOCUS_GAME_TYPE_ID, FOCUS_LEAGUE_ID, FOCUS_SEASON_ID, FOCUS_TEAM_ID, SET_FOCUS_TEAM_ID} from '@/store/context.store';
import DataTable from '@/components/stats/DataTable.vue';
import StatsTableLayout from '@/components/layout/StatsTableLayout.vue';
import StatsSeasonSelection from '@/components/stats/selection/StatsSeasonSelection.vue';
import StatsLeagueSelection from '@/components/stats/selection/StatsLeagueSelection.vue';
import StatsGameTypeSelection from '@/components/stats/selection/StatsGameTypeSelection.vue';
import StatsTeamSelection from '@/components/stats/selection/StatsTeamSelection.vue';
import {ROUTES} from '@/router';
import {toArray} from '@/utils/common.util';
import PanelBox from '@/components/ui/PanelBox.vue';
import {gamestatsTables} from '@/services/tables/gamestats.tables';
import { CustomComponent } from '@/model/site/components.model';

@Component({
  components: {PanelBox, StatsTableLayout, DataTable},
})
export default class GameStatsPage extends Vue {

  // TODO [feature] kein FocusTeam -> Spiele reduzieren

  @Prop() preselectedTeamId?: string;

  @Getter(FOCUS_SEASON_ID) focusSeasonId!: string;
  @Getter(FOCUS_GAME_TYPE_ID) focusGameTypeId!: string;
  @Getter(FOCUS_LEAGUE_ID) focusLeagueId!: string;
  @Getter(FOCUS_TEAM_ID) focusTeamId!: string;

  gameStats = toArray(gamestatsTables);
  readonly pageSize = 15;

  beforeMount() {
    if (this.preselectedTeamId && this.preselectedTeamId !== this.focusTeamId) {
      this.$store.commit(SET_FOCUS_TEAM_ID, this.preselectedTeamId);
    }
  }

  get selectionComponents(): CustomComponent[] {
    return [
      {component: StatsLeagueSelection},
      {component: StatsSeasonSelection},
      {component: StatsGameTypeSelection},
      {component: StatsTeamSelection},
    ];
  }

  onPageChange(page: any) {
    const dataTables = <any> this.$refs.dataTable;
    dataTables.forEach((dataTable: any) => dataTable.currentPage = page);
  }

  get queryParams() {
    return {
      season: this.focusSeasonId,
      league: this.focusLeagueId,
      gameType: this.focusGameTypeId,
      ha: 'both',
      orderBy: 'gameDate'
    };
  }

  fieldIds(fields: []) {
    let fieldIds = [
      'gameId',
      'gameDate'
    ];
    if (this.focusTeamId) {
      fieldIds = [...fieldIds, 'oppTeamId', 'ha'];
    } else {
      fieldIds = [...fieldIds, 'homeTeamId', 'awayTeamId'];
    }
    fieldIds = [...fieldIds, ...fields];
    return fieldIds;
  }

  @Watch(FOCUS_TEAM_ID)
  onFocusTeamChanged(newValue: string, oldValue: string) {
    if (newValue === oldValue) {
      return;
    }
    if (this.$route.params.preselectedTeamId === newValue) {
      return;
    }
    this.$router.replace({name: ROUTES.STATS_GAME, params: {preselectedTeamId: newValue}});
  }

}
