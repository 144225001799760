const pp = {
	title: 'Powerplay',
	query: 'powerplay',
	fields: [
		'gteam',
		'gopp',
		'ppGoalsFor',
		'ppGoalsPct',
		'ppGoalsAgainst',
		'ppOpp',
		'ppTime',
		'ppGoalTime',
		'ppQuote',
	]
};

const sh = {
	title: 'Unterzahl',
	query: 'boxplay',
	fields: [
		'gteam',
		'gopp',
		'shGoalsAgainst',
		'shGoalsAgainstPct',
		'shGoalsFor',
		'shOpp',
		'shTime',
		'shGoalTime',
		'shQuote',
	]
};

const leading = {
	title: 'Führungszeiten',
	query: 'leading',
	fields: [
		'lag3_10Pct',
		'lag2Pct',
		'lag1Pct',
		'lagPct',
		'eqPct',
		'leadPct',
		'lead1Pct',
		'lead2Pct',
		'lead3_10Pct',
		'avgLeading',
	]
};

const penalties = {
	title: 'Strafen',
	query: 'penalties',
	fields: [
		'ps',
		'min2',
		'min5',
		'min10',
		'min20',
		'min25',
		'pim',
		'psOpp',
		'min2Opp',
		'min5Opp',
		'min10Opp',
		'min20Opp',
		'min25Opp',
		'pimOpp',
	]
};

const goals = {
	title: 'Tore',
	query: 'goals',
	fields: [
		'gteam',
		'gopp',
		'ppGoalsFor',
		'shGoalsAgainst',
		'shGoalsFor',
		'ppGoalsAgainst',
		'en',
		'enOpp',
		'wg',
		'wgOpp',
		'sog',
		'sogOpp',
		'sogPct',
		'sogPctOpp',
		'plmi',
		'plmiOpp',
	]
};

const overview = {
	title: 'Übersicht',
	query: 'overview',
	fields: [
		'gteam',
		'gopp',
		// TODO Resultat
		'attendance',
		'sogPct',
		'sogPctOpp',
		'min2',
		'min5',
		'min10',
		'disc',
		'ppGoalTime',
		'ppQuote',
		'shGoalTime',
		'shQuote',
		'avgLeading',
	]
};

export const gamestatsTables = {
	overview,
	penalties,
	pp,
	sh,
	goals,
	leading,
};
