import {Season, Team} from '@/model/core/core-data.model';

export interface State {
	prefs: Prefs;
	data: StateData;
}

export interface StateData {
	seasons: IndexedSeason;
	games: IndexedGameCount;
	gamesPerTeam: IndexedGameCount;
	teams: IndexedTeams;
}

export interface IndexedTeams {
	[key: string]: IndexedTeam;
}

export interface IndexedTeam {
	[teamId: string]: Team;
}

export interface IndexedSeason {
	[seasonId: string]: Season;
}

export interface IndexedGameCount {
	// 1819-a-m(-ber) => 16
	[key: string]: number;
}

export function teamSeasonKey(seasonId: string, leagueId: string): string {
	return `${seasonId}-${leagueId}`;
}

/** Key for season-league-gametype */
export function slg(gameCount: GameCount): string {
	return `${gameCount.seasonId}-${gameCount.leagueId}-${gameCount.gameTypeId}`;
}

/** Key for season-league-gametype-team */
export function slgt(gameCount: GameCount): string {
	return `${gameCount.seasonId}-${gameCount.leagueId}-${gameCount.gameTypeId}-${gameCount.teamId}`;
}

// TODO [cleanup] delete

export interface Prefs {
	seasonId: string;
	leagueId: string;
	gameTypeId: string;
	teamId?: string;
	homeAway: string;
	integrated: boolean;
}

export interface GameCount {
	seasonId: string;
	leagueId: string;
	gameTypeId: string;
	teamId: string;
	count?: number;
}
