import Common from '../utils/common.util';
import Vue from 'vue';
import {BASE_URL} from './base.service';
import dayjs from 'dayjs';
import { StatsData } from '@/model/stats/stats.model';


const PLAYER_BASE_URL = `${BASE_URL}/players`;

// tslint:disable-next-line:variable-name
const PLAYER_SERVICE = {
	playerById: function (playerId: string) {
		Vue.$log.debug(`loading player ${playerId}`);
		return Vue.http.get(`${PLAYER_BASE_URL}/${playerId}`);
	},
	playerPictures: function (playerId: string, maxWidth: number) {
		Vue.$log.debug(`loading pictures of player ${playerId}`);
		return Vue.http.get(Common.links.playerPictures(playerId, maxWidth));
	},
	birthdayTodays: function () {
		// TODO use interceptor for date conversion
		return Vue.http.get(`${PLAYER_BASE_URL}/todaysBirthdays`)
			.then((response: any) => {
				response.data.forEach((p: any) => {
					p.birthdate = dayjs(p.birthdate).toDate();
				});
				return response.data;
			});
	},
	seasonsForPlayer: function(playerId: string): Promise<StatsData> {
		return Vue.http.get(`${BASE_URL}/playerstats/seasons-for-player/${playerId}`);
	}
};

export default PLAYER_SERVICE;
