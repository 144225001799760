





































import {Component, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import {CONTEXT, FOCUS_GAME_TYPE_ID, FOCUS_LEAGUE_ID, FOCUS_SEASON_ID} from '@/store/context.store';
import {Context} from '@/model/site/context.model';
import StatsLeagueSelection from '@/components/stats/selection/StatsLeagueSelection.vue';
import DataTable from '@/components/stats/DataTable.vue';
import Loading from 'vue-loading-overlay';
import StatsPortalLayout from '@/components/layout/StatsPortalLayout.vue';
import {StatsTopPlayerTO} from '@/model/stats/stats.model';
import PanelBox from '@/components/ui/PanelBox.vue';
import StatsSeasonSelection from '@/components/stats/selection/StatsSeasonSelection.vue';
import StatsGameTypeSelection from '@/components/stats/selection/StatsGameTypeSelection.vue';
import StatsTeamSelection from '@/components/stats/selection/StatsTeamSelection.vue';
import {CustomComponent} from '@/model/site/components.model';

@Component({
  components: {StatsPortalLayout, DataTable, Loading, PanelBox},
})
export default class LeaderStatsPage extends Vue {

  @Getter(CONTEXT) context!: Context;
  @Getter(FOCUS_SEASON_ID) focusSeasonId!: string;
  @Getter(FOCUS_LEAGUE_ID) focusLeagueId!: string;
  @Getter(FOCUS_GAME_TYPE_ID) focusGameTypeId!: string;

  get selectionComponents(): CustomComponent[] {
    return [
      {component: StatsLeagueSelection},
      {component: StatsSeasonSelection},
      {component: StatsGameTypeSelection},
      {component: StatsTeamSelection},
    ];
  }

  statsTopPlayer = [
    new StatsTopPlayerTO('Tore', 'playerstats', 'top', 'g'),
    new StatsTopPlayerTO('Assists', 'playerstats', 'top', 'a'),
    new StatsTopPlayerTO('Plus-Minus ↓', 'playerstats', 'top', 'plmi', 'plmi_max'),
    new StatsTopPlayerTO('Plus-Minus ↑', 'playerstats', 'top', 'plmi', 'plmi_min'),
    new StatsTopPlayerTO('Powerplay-Tore', 'playerstats', 'top', 'ppg'),
    new StatsTopPlayerTO('Shorthander', 'playerstats', 'top', 'shg'),
    // TODO [stats] nur die ab einem gewissen Mindestanzahl Spielen
    new StatsTopPlayerTO('Schusseffizienz', 'playerstats', 'top', 'sogPct', 'sog_pct'),
    new StatsTopPlayerTO('Schüsse', 'playerstats', 'top', 'sog'),
    new StatsTopPlayerTO('2-Minuten-Strafen', 'playerstats', 'top', 'min2'),
    new StatsTopPlayerTO('5-Minuten-Strafen', 'playerstats', 'top', 'min5'),
    new StatsTopPlayerTO('10-Minuten-Strafen', 'playerstats', 'top', 'min10'),
    new StatsTopPlayerTO('Spieldauer-und Matchstrafen', 'playerstats', 'top', 'disc'),
  ];
  statsTopGoalie = [
    // TODO [stats] nur die ab einem gewissen Mindestanzahl Spielen
    new StatsTopPlayerTO('Schüsse gehalten ↓', 'goaliestats', 'top', 'svPct', 'sv_pct'),
    new StatsTopPlayerTO('Schüsse gehalten ↑', 'goaliestats', 'top', 'svPct', 'sv_pct_reverse'),
    new StatsTopPlayerTO('Gegentore pro 60\' ↑', 'goaliestats', 'top', 'ga_60'),
    new StatsTopPlayerTO('Gegentore pro 60\' ↓', 'goaliestats', 'top', 'ga_60', 'ga_60_reverse'),
    new StatsTopPlayerTO('Gespielte Minuten', 'goaliestats', 'top', 'toi'),
    new StatsTopPlayerTO('Shutouts', 'goaliestats', 'top', 'so', 'so', 3),
    new StatsTopPlayerTO('Siege', 'goaliestats', 'top', 'won', 'won', 3),
    new StatsTopPlayerTO('Niederlagen', 'goaliestats', 'top', 'loss', 'loss', 3),
    // new StatsTopPlayerTO('', 'goaliestats', 'top', ''),
  ];

  /** same as in StatsPortalPage */
  get boxes() {
    const statsLeaders = this.statsTopGoalie.map(s => {
      return {
        title: s.title,
        component: DataTable,
        props: {
          resource: s.resource,
          query: s.query,
          fieldIds: s.fieldIds,
          queryParams: {
            season: this.focusSeasonId,
            league: this.focusLeagueId,
            gameType: this.focusGameTypeId,
            limitRows: s.limitRows,
            orderBy: s.sortField
          },
          showHeader: false,
          suppressPaging: true,
        },
      };
    });

    return [...statsLeaders];
  }

}
