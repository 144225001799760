



















import {Component, Prop, Vue} from 'vue-property-decorator';
import {Context} from '@/model/site/context.model';
import {Getter} from 'vuex-class';
import {CONTEXT, FOCUS_SEASON_ID, SET_FOCUS_SEASON_ID} from '@/store/context.store';
import SEASON_SERVICE from '@/services/season.service';
import {Season} from '@/model/core/core-data.model';
import {StatsData} from '@/model/stats/stats.model';

@Component({
  components: {},
})
export default class StatsSeasonSelection extends Vue {

  @Getter(CONTEXT) context!: Context;
  @Getter(FOCUS_SEASON_ID) focusSeasonId!: string;

  @Prop({type: Array}) statsSeasonData!: StatsData[];

  seasonsData: Season[] = [];

  get hasStatsData() {
    return this.seasons && this.seasons.length > 0;
  }

  onSeasonSelected(value: string) {
    this.selectSeason(value);
  }

  selectSeason(value: string) {
    this.$store.commit(SET_FOCUS_SEASON_ID, value);
  }

  get seasonDesc() {
    return this.seasonDescFor(this.focusSeasonId);
  }

  get seasons(): string[] {
    if (this.statsSeasonData) {
      if (this.statsSeasonData.length) {
        return this.statsSeasonData.map((s: any) => s.seasonId);
      } else {
        return [];
      }
    }
    return this.seasonsData.map((s: any) => s.seasonId);
  }

  created() {
    this.seasonsData = SEASON_SERVICE.all();
  }

  seasonDescFor(seasonId: string): string {
    return SEASON_SERVICE.seasonDesc(seasonId, false, 'Saison ');
  }

}
